@import "preflight.css";
@tailwind base;
@layer base {
  * {
    max-width: none;
  }
}
@tailwind components;
@tailwind utilities;

#ta-widget-container {
  position: relative;
  font-family: Helvetica, Arial, sans-serif !important;
}

#ta-widget-cotnainer .modal + .ta-search--by-size {
  display: none !important;
}

#ta-widget-container .tab-active {
  color: #000 !important;
}

#ta-widget-container .ta-dropdown__toggle:after {
  content: "";
  position: absolute;
  top: 20px;
  right: 0;
  border: 8px solid transparent;
  border-top-width: 5px;
  border-bottom-width: 5px;
  border-left-color: currentColor;
}

#ta-widget-container .ta-dropdown__toggle.active:after {
  top: 18px;
  transform: rotate(-30deg);
}

#ta-widget-container .ta-dropdown__list {
  scrollbar-width: auto;
  scrollbar-color: #7d92a1 #ecf0f3;
}

/* Chrome, Edge, and Safari */
#ta-widget-container .ta-dropdown__list::-webkit-scrollbar {
  width: 8px;
}

#ta-widget-container .ta-dropdown__list::-webkit-scrollbar-track {
  border-radius: 4px;
  background: #ecf0f3;
}

#ta-widget-container .ta-dropdown__list::-webkit-scrollbar-thumb {
  background-color: #7d92a1;
  border-radius: 4px;
  border: 2px solid #ecf0f3;
}

.ta-custom-checkbox input:checked + label svg {
  opacity: 1 !important;
}

.ta-exclusive-offer:hover div {
  display: block !important;
}

#ta-widget-container .ta-search-results .p-w-r .pr-snippet-rating-decimal,
.ta-product-detail-reviews .pr-review-snippet-container,
.ta-product-detail-reviews .pr-review-snapshot-header-intro .pr-headline,
#ta-widget-container
  .p-w-r
  .pr-review-snapshot.pr-review-enhanced
  .pr-review-snapshot-block-container
  .pr-review-snapshot-block.pr-review-snapshot-block-recommend
  .pr-review-snapshot-recomend,
.ta-print .ta-search-results .p-w-r .pr-snippet-rating-decimal,
.ta-print .ta-product-detail-reviews .pr-review-snippet-container,
.ta-print
  .ta-product-detail-reviews
  .pr-review-snapshot-header-intro
  .pr-headline,
.ta-print
  .p-w-r
  .pr-review-snapshot.pr-review-enhanced
  .pr-review-snapshot-block-container
  .pr-review-snapshot-block.pr-review-snapshot-block-recommend
  .pr-review-snapshot-recomend,
#ta-widget-container .ta-product-detail-reviews .pr-snippet-rating-decimal,
.ta-print .ta-product-detail-reviews .pr-snippet-rating-decimal,
#ta-widget-container
  .ta-product-detail-body__reviews
  .pr-snippet-rating-decimal,
#ta-widget-container
  .ta-product-detail-body__reviews
  .pr-snippet-write-review-link,
#ta-widget-container
  .ta-product-detail-body__reviews
  .pr-snippet-stars-reco-reco,
#ta-widget-container .pr-rd-to-top,
#ta-widget-container .ta-compare-product__reviews .pr-snippet-rating-decimal,
#ta-widget-container .pr-ggl_image-btn,
#ta-widget-container .pr-snippet-write-review-link {
  display: none !important;
}

#ta-widget-container .ta-compare-product__reviews .pr-category-snippet__total {
  font-size: 12px;
}

#ta-widget-container
  .ta-product-detail-body__reviews
  .pr-snippet-read-and-write {
  margin-top: 0;
}

#ta-widget-container .ta-product-detail-body__reviews .pr-snippet-review-count {
  background: none;
  font-size: 12px;
  text-decoration: underline;
  color: rgba(var(--color-link) / 1);
}

#ta-widget-container
  .ta-product-detail-body__reviews
  .pr-snippet-review-count:hover,
#ta-widget-container
  .ta-product-detail-body__reviews
  .pr-snippet-review-count:focus {
  text-decoration: none;
}

/* "Power Reviews" branding link */
.ta-print .ta-product-detail-reviews .pr-subheadline,
.ta-product-detail-reviews .pr-subheadline {
  margin-left: auto;
}

#ta-widget-container h2 {
  background: none !important;
  letter-spacing: 0;
}

#ta-widget-container
  .p-w-r
  .pr-review-snapshot
  .pr-ratings-histogram
  .pr-histogram-count {
  width: 60px;
}

.ta-print .ta-product-detail-reviews .pr-subheadline svg:nth-child(2) {
  display: none !important;
}

/* Reviews Header */
.ta-print
  .ta-product-detail-reviews
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-header-intro,
#ta-widget-container
  .ta-product-detail-reviews
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-header-intro {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 23px;
  padding-bottom: 17px;
}

#ta-widget-container .p-w-r .pr-review-snapshot .pr-faceoff-readcomplete {
  position: static;
}

.ta-print
  .ta-product-detail-reviews
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-header-intro:before,
#ta-widget-container
  .ta-product-detail-reviews
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-header-intro:before {
  content: "Reviews";
  font-size: 30px;
  font-weight: bold;
  color: rgba(var(--color-tertiary) / 1);
}

.ta-product-detail-reviews
  .p-w-r
  .pr-review-snapshot.pr-review-enhanced
  .pr-review-snapshot-header {
  margin: 0;
}

/* Snapshot section with number of rating bars */
.ta-print
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets,
#ta-widget-container
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets {
  text-align: center;
}

.ta-print
  .ta-product-detail-reviews
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets-headline,
#ta-widget-container
  .ta-product-detail-reviews
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets-headline {
  display: block;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
  color: black;
}

.ta-print
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-review-count,
#ta-widget-container
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-review-count {
  margin: 13px 0 10px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: black;
}

.ta-print
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-read-and-write,
#ta-widget-container
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-read-and-write {
  text-align: center;
}

.ta-print
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-read-and-write
  a,
#ta-widget-container
  .p-w-r
  .pr-review-snapshot
  .pr-review-snapshot-simple
  .pr-review-snapshot-snippets
  .pr-snippet-read-and-write
  a {
  width: auto !important;
  margin: 0 auto;
  border-radius: 4px;
  padding: 12px 50px;
  background: rgba(var(--color-tertiary) / 1);
  font-size: 12px;
  color: white;
}

/* Search and Sort */
.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 18px 23px;
  margin-bottom: 8px;
  background: #f5f5f5;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search {
  order: 2;
  padding: 0;
  float: none;
  height: auto;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  .pr-rd-search-container,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  .pr-rd-search-container {
  margin-top: 0;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  input,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  input {
  border: 1px solid #707070;
  text-align: center;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  button,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  button {
  background: url(assets/search-icon.png) rgba(var(--color-tertiary) / 1)
    no-repeat center center;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  button
  svg,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-search
  button
  svg {
  display: none;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-review-header-sorts
  .pr-rd-sort,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-review-header-sorts
  .pr-rd-sort {
  width: 100%;
  max-width: 100%;
  height: 27px;
  border-radius: 2px;
  border: 1px solid #707070;
  padding-left: 8px;
  text-align: left;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-review-header-sorts
  .pr-rd-sort-group,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-review-header-sorts
  .pr-rd-sort-group {
  padding: 0;
}

.ta-print
  .p-w-r
  .pr-review-snapshot
  .pr-ratings-histogram
  .pr-ratings-histogram-barValue,
#ta-widget-container
  .p-w-r
  .pr-review-snapshot
  .pr-ratings-histogram
  .pr-ratings-histogram-barValue {
  background: rgba(var(--color-tertiary) / 1);
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-sorts-w-search,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-header-with-filters
  .pr-rd-main-header-search-sort
  .pr-rd-main-header-sorts-w-search {
  width: 100%;
  margin-right: auto;
  padding: 0;
}

.ta-print .p-w-r .pr-review-display .pr-rd-review-total,
.ta-print .p-w-r .pr-review-display .pr-rd-review-headline,
#ta-widget-container .p-w-r .pr-review-display .pr-rd-review-total,
#ta-widget-container .p-w-r .pr-review-display .pr-rd-review-headline {
  background: none !important;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.ta-print .p-w-r .pr-review-display .pr-review-filter-info-bar,
#ta-widget-container .p-w-r .pr-review-display .pr-review-filter-info-bar {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  margin: 0.5rem 0;
  padding: 1rem;
  background: #f5f5f5;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-review-filter-info-bar
  .pr-review-filter-clear-all,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-review-filter-info-bar
  .pr-review-filter-clear-all {
  margin-left: auto;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-review-filter-info-bar
  .pr-review-filter-headline,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-review-filter-info-bar
  .pr-review-filter-headline {
  width: auto;
}

/* Start image Overrides */
.ta-print .p-w-r .pr-star-v4-100-filled,
.ta-print .p-w-r .pr-star-v4-0-filled,
.ta-print .p-w-r .pr-star-v4-50-filled,
#ta-widget-container .p-w-r .pr-star-v4-100-filled,
#ta-widget-container .p-w-r .pr-star-v4-0-filled,
#ta-widget-container .p-w-r .pr-star-v4-50-filled {
  width: 14px;
  height: 14px;
}

.ta-print .ta-product-detail-reviews .p-w-r .pr-star-v4-100-filled,
.ta-print .ta-product-detail-reviews .p-w-r .pr-star-v4-0-filled,
#ta-widget-container .ta-product-detail-reviews .p-w-r .pr-star-v4-100-filled,
#ta-widget-container .ta-product-detail-reviews .p-w-r .pr-star-v4-0-filled {
  width: 18px;
  height: 18px;
}

.ta-print .p-w-r .pr-star-v4-100-filled,
#ta-widget-container .p-w-r .pr-star-v4-100-filled {
  background-image: url(assets/star.png);
}

.ta-print .p-w-r .pr-star-v4-0-filled,
#ta-widget-container .p-w-r .pr-star-v4-0-filled {
  background-image: url(assets/star-hollow.png);
}

.ta-print .p-w-r .pr-star-v4-50-filled,
#ta-widget-container .p-w-r .pr-star-v4-50-filled {
  background-image: url(assets/star-half.png);
}

.ta-print .p-w-r .pr-snippet .pr-snippet-stars-png,
.ta-print .p-w-r .pr-review-display .pr-snippet-stars,
#ta-widget-container .p-w-r .pr-snippet .pr-snippet-stars-png,
#ta-widget-container .p-w-r .pr-review-display .pr-snippet-stars {
  display: flex;
  align-items: center;
}

.ta-print .ta-product-detail-reviews .p-w-r .pr-snippet .pr-snippet-stars-png,
#ta-widget-container
  .ta-product-detail-reviews
  .p-w-r
  .pr-snippet
  .pr-snippet-stars-png {
  justify-content: center;
}

/* Review Content Overrdies */
.ta-print .p-w-r .pr-review-display p,
.ta-print .p-w-r .pr-review-display span,
.ta-print .p-w-r .pr-review-display time,
#ta-widget-container .p-w-r .pr-review-display p,
#ta-widget-container .p-w-r .pr-review-display span,
#ta-widget-container .p-w-r .pr-review-display time {
  color: black !important;
}

.ta-print .p-w-r .pr-review-display .pr-rd-description,
#ta-widget-container .p-w-r .pr-review-display .pr-rd-description {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-description-text,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-description-text {
  margin-bottom: 16px;
  order: -1;
}

.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-side-content-block,
.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-side-content-block
  .pr-rd-reviewer-details,
.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-side-content-block
  p,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-side-content-block,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-side-content-block
  .pr-rd-reviewer-details,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-content-block
  .pr-rd-side-content-block
  p {
  margin: 0;
}

.ta-print .p-w-r .pr-snippet-rating-decimal,
#ta-widget-container .p-w-r .pr-snippet-rating-decimal {
  float: none;
  height: auto;
  padding: 0 0 0 8px;
  font-size: 12px;
  color: rgba(var(--color-tertiary) / 1);
}

.ta-print .ta-product-detail-reviews .p-w-r .pr-snippet-rating-decimal,
#ta-widget-container
  .ta-product-detail-reviews
  .p-w-r
  .pr-snippet-rating-decimal {
  margin-right: 16px;
  font-size: 14px;
}

.ta-print .pr-rd-helpful-action-group,
#ta-widget-container .pr-rd-helpful-action-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.ta-print .pr-rd-helpful-action-legend,
#ta-widget-container .pr-rd-helpful-action-legend {
  width: 100%;
}

.ta-print .p-w-r .pr-review-display .pr-rd-bottomline,
#ta-widget-container .p-w-r .pr-review-display .pr-rd-bottomline {
  margin-bottom: 16px;
}

.ta-print .p-w-r .pr-rd-flag-review-btn,
#ta-widget-container .p-w-r .pr-rd-flag-review-btn {
  text-decoration: none;
  text-transform: uppercase;
  color: rgba(var(--color-tertiary) / 1);
}

.ta-print .p-w-r .pr-review-display .pr-helpful-btn,
#ta-widget-container .p-w-r .pr-review-display .pr-helpful-btn {
  border-radius: 0;
  border: 1px solid #707070;
  padding: 3px 4px;
}

.ta-print .p-w-r .pr-review-display .pr-helpful-btn + .pr-helpful-btn,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-helpful-btn
  + .pr-helpful-btn {
  border-left: 0;
}

.ta-print .p-w-r .pr-review-display .pr-rd-main-footer .pr-rd-content-block,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-footer
  .pr-rd-content-block {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Review Footer overrides */
.ta-print
  .p-w-r
  .pr-review-display
  .pr-rd-main-footer
  .pr-rd-content-block
  .pr-rd-review-position,
#ta-widget-container
  .p-w-r
  .pr-review-display
  .pr-rd-main-footer
  .pr-rd-content-block
  .pr-rd-review-position {
  margin: 0 auto;
  order: 2;
}

#ta-widget-container .pr-btn-review {
  margin-top: 1rem;
  background: rgba(var(--color-tertiary) / 1);
  color: #fff;
}

#ta-widget-container .p-w-r .pr-btn-fileinput {
  margin-left: 10px;
  padding: 6px 12px;
}

#ta-widget-container .ta-checkout-select .ta-select__control {
  border: 1px solid #8f8f8f;
  height: 33px;
  border-radius: 3px;
  padding: 0.5rem;
  min-height: 0;
}

#ta-widget-container .ta-checkout-select .ta-select__indicator {
  padding: 0;
}

#ta-widget-container .ta-checkout-select .ta-select__indicator svg {
  width: 15px;
  height: 15px;
}

#ta-widget-container .ta-select__menu {
  margin-top: 0 !important;
}

#ta-widget-container .ta-checkout-select .ta-select__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

#ta-widget-container .ta-checkout-select .ta-select__input-container {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  #ta-widget-container
    .p-w-r
    .pr-review-display
    .pr-rd-main-header-with-filters
    .pr-rd-main-header-search-sort {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    padding: 18px 23px;
    margin-bottom: 8px;
    background: #f5f5f5;
  }

  #ta-widget-container
    .p-w-r
    .pr-review-display
    .pr-rd-main-header-with-filters
    .pr-rd-main-header-search-sort
    .pr-rd-main-header-sorts-w-search {
    width: auto;
  }

  #ta-widget-container
    .ta-product-detail-reviews
    .p-w-r
    .pr-review-snapshot
    .pr-review-snapshot-simple {
    display: flex;
  }

  #ta-widget-container
    .p-w-r
    .pr-review-snapshot.pr-review-enhanced
    .pr-review-snapshot-simple
    .pr-review-snapshot-block {
    width: 32.6%;
  }

  #ta-widget-container
    .p-w-r
    .pr-review-snapshot
    .pr-review-snapshot-block-container
    .pr-review-snapshot-block.pr-review-snapshot-block-recommend {
    position: relative;
    display: block !important;
    width: auto;
    min-height: 143px;
    border-left: 1px solid #d3d3d3;
    margin: 0 auto;
    padding: 0;
  }

  #ta-widget-container
    .p-w-r
    .pr-review-snapshot.pr-review-enhanced
    .pr-review-snapshot-simple
    .pr-review-snapshot-block-histogram {
    width: 51.7%;
    margin-left: 0;
  }

  #ta-widget-container .pr-rd-helpful-action-legend {
    width: auto;
  }

  #ta-widget-container
    .p-w-r
    .pr-review-display
    .pr-rd-main-header-with-filters
    .pr-rd-main-header-search-sort
    .pr-rd-main-header-search {
    flex-grow: 1;
  }
}

@media (min-width: 1024px) {
  #ta-widget-container .p-w-r .pr-review-display .pr-rd-description {
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  #ta-widget-container
    .p-w-r
    .pr-review-snapshot.pr-review-enhanced
    .pr-review-snapshot-simple
    .pr-review-snapshot-block-histogram {
    width: 40.7%;
  }

  #ta-widget-container
    .p-w-r
    .pr-review-display
    .pr-rd-main-header-with-filters
    .pr-rd-main-header-search-sort
    .pr-rd-main-header-search {
    flex-grow: 0;
  }
}

@media print {
  html,
  body {
    height: 100%;
    overflow: hidden;
  }
}

@media print {
  .ta-cart-details table,
  .ta-wishlist-summary table {
    width: 100%;
    border-collapse: collapse;
  }

  .ta-cart-details table thead,
  .ta-wishlist-summary table thead {
    border-color: #000;
    background: #000;
    color: #fff;
  }

  .ta-cart-details table tbody tr > td,
  .ta-wishlist-summary table tbody tr > td {
    padding: 0 10px;
    border-bottom: 1px solid #e4e2e2;
    vertical-align: middle;
  }

  .ta-cart-details table tbody tr > td:first-child,
  .ta-wishlist-summary table tbody tr > td:first-child {
    padding: 20px 28px 20px 12px;
  }

  .ta-cart-details table thead tr > th:nth-last-child(2),
  .ta-cart-details table tbody tr > td:nth-last-child(2),
  .ta-wishlist-summary table thead tr > th:nth-last-child(2),
  .ta-wishlist-summary table tbody tr > th:nth-last-child(2) {
    column-span: 2;
  }

  .ta-cart-details table thead tr th:last-child,
  .ta-cart-details table tbody tr > td:last-child,
  .ta-print-none {
    display: none;
  }

  .ta-wishlist-summary {
    padding: 1rem;
  }

  .ta-compare-overlay-header {
    display: none !important;
  }

  .ta-compare-overlay-items {
    display: flex !important;
  }

  /* Product Detail Print */
  .ta-product-detail-body {
    padding: 1rem;
  }

  .ta-product-detail-body h1 {
    font-size: 24px;
  }

  .ta-product-detail-body h2 {
    display: block;
    margin-top: 1rem;
    font-size: 20px;
  }

  .ta-product-detail-body__top {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
  }

  .ta-product-detail-body__grid {
    display: grid;
    grid-template-columns: 1fr 205px;
    gap: 15px;
  }

  .ta-product-detail-body__img {
    display: block;
    margin: 0 auto;
  }

  .ta-product-detail-body__pricing {
    text-align: right;
    align-self: center;
  }

  .ta-product-detail-body__price {
    font-size: 24px;
    font-weight: bold;
  }

  .ta-product-detail-body button {
    border: 0;
    margin: 0;
    padding: 0;
    background: none;
  }

  .ta-product-detail-body__quantity {
    display: inline-flex;
    justify-content: space-between;
    border: 1px solid #000;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
  }

  .ta-product-detail-body__quantity input {
    width: 50px;
    border: 0;
    text-align: center;
  }

  .ta-product-detail-body__info > div {
    display: grid;
    flex-grow: 1;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
  }

  .ta-product-detail-body__info > div:nth-child(even) {
    background: #d3d3d3;
  }

  .ta-product-detail-body__info > div span {
    font-weight: bold;
  }

  .ta-product-detail-body img {
    width: auto;
  }

  .ta-product-detail-reviews {
    page-break-before: always;
  }
}
