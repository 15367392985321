/*
    1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
    2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
    */
#ta-widget-container *,
#ta-widget-container ::before,
#ta-widget-container ::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: currentColor; /* 2 */
  box-shadow: none;
}
#ta-widget-container ::before,
#ta-widget-container ::after {
  --tw-content: "";
}
/*
      1. Use a consistent sensible line-height in all browsers.
      2. Prevent adjustments of font size after orientation changes in iOS.
      3. Use a more readable tab size.
      4. Use the user's configured `sans` font-family by default.
      */
#ta-widget-container html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */ /* 3 */
  tab-size: 4; /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
}
@media print {
  .no-print {
    display: none;
  }
  .print-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  #img-container {
    display: flex;
    justify-content: center;
    font-size: 0.875rem; /* this corresponds to tailwind's 'text-sm' */
    width: 100%;
    align-self: center;
    border-bottom: 1px solid gray;
  }
  #img-container img {
    padding: 1.25rem;
    padding-left: 0;
    margin: 0;
    height: 145px;
    width: auto;
  }
  .print-order-1 {
    order: 1;
    text-align: center;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: none;
    margin-top: 50px;
  }
  .print-order-2 {
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1.4rem;
  }
  .print-imgCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .print-imgContainer {
    margin-top: 5rem;
  }
  .print-totalCard {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid gray;
    margin-bottom: 1rem;
  }
  .print-left {
    text-align: left;
  }
  .print-right {
    text-align: right;
  }
}
.calendar-button {
  max-height: 30px !important;
}
.calendar-input {
  max-height: 30px !important;
}
.css-5em5qi-option,
.css-1r9h27f-option,
.css-1ftcqwk-option {
  width: auto !important;
}
.pr-rd-review-position {
  order: 0 !important;
}

/*
      1. Remove the margin in all browsers.
      2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
      */
#ta-widget-container body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}
/*
      1. Add the correct height in Firefox.
      2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
      3. Ensure horizontal rules are visible by default.
      */
#ta-widget-container hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}
/*
      Add the correct text decoration in Chrome, Edge, and Safari.
      */
#ta-widget-container abbr:where([title]) {
  text-decoration: underline dotted;
}
/*
      Remove the default font size and weight for headings.
      */
#ta-widget-container h1,
#ta-widget-container h2,
#ta-widget-container h3,
#ta-widget-container h4,
#ta-widget-container h5,
#ta-widget-container h6 {
  font-size: inherit;
  font-weight: inherit;
}
.rc-time-picker-panel-input::placeholder {
  font-size: 0.8rem;
}

/*
      Reset links to optimize for opt-in styling instead of opt-out.
      */
#ta-widget-container a {
  color: inherit;
  text-decoration: inherit;
}
/*
      Add the correct font weight in Edge and Safari.
      */
#ta-widget-container b,
#ta-widget-container strong {
  font-weight: bolder;
}
/*
      1. Use the user's configured `mono` font family by default.
      2. Correct the odd `em` font sizing in all browsers.
      */
#ta-widget-container code,
#ta-widget-container kbd,
#ta-widget-container samp,
#ta-widget-container pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/*
      Add the correct font size in all browsers.
      */
#ta-widget-container small {
  font-size: 80%;
}
/*
      Prevent `sub` and `sup` elements from affecting the line height in all browsers.
      */
#ta-widget-container sub,
#ta-widget-container sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
#ta-widget-container sub {
  bottom: -0.25em;
}
#ta-widget-container sup {
  top: -0.5em;
}
/*
      1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
      2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
      3. Remove gaps between table borders by default.
      */
#ta-widget-container table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}
/*
      1. Change the font styles in all browsers.
      2. Remove the margin in Firefox and Safari.
      3. Remove default padding in all browsers.
      */
#ta-widget-container button,
#ta-widget-container input,
#ta-widget-container optgroup,
#ta-widget-container select,
#ta-widget-container textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}
/*
      Remove the inheritance of text transform in Edge and Firefox.
      */
#ta-widget-container button,
#ta-widget-container select {
  text-transform: none;
}
/*
      1. Correct the inability to style clickable types in iOS and Safari.
      2. Remove default button styles.
      */
#ta-widget-container button,
#ta-widget-container [type="button"],
#ta-widget-container [type="reset"],
#ta-widget-container [type="submit"] {
  -webkit-appearance: button; /* 1 */
  background-color: transparent; /* 2 */
  background-image: none; /* 2 */
}
/*
      Use the modern Firefox focus style for all focusable elements.
      */
#ta-widget-container :-moz-focusring {
  outline: auto;
}
/*
      Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
      */
#ta-widget-container :-moz-ui-invalid {
  box-shadow: none;
}
/*
      Add the correct vertical alignment in Chrome and Firefox.
      */
#ta-widget-container progress {
  vertical-align: baseline;
}
/*
      Correct the cursor style of increment and decrement buttons in Safari.
      */
#ta-widget-container ::-webkit-inner-spin-button,
#ta-widget-container ::-webkit-outer-spin-button {
  height: auto;
}
/*
      1. Correct the odd appearance in Chrome and Safari.
      2. Correct the outline style in Safari.
      */
#ta-widget-container [type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/*
      Remove the inner padding in Chrome and Safari on macOS.
      */
#ta-widget-container ::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
      1. Correct the inability to style clickable types in iOS and Safari.
      2. Change font properties to `inherit` in Safari.
      */
#ta-widget-container ::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/*
      Add the correct display in Chrome and Safari.
      */
#ta-widget-container summary {
  display: list-item;
}
/*
      Removes the default spacing and border for appropriate elements.
      */
#ta-widget-container blockquote,
#ta-widget-container dl,
#ta-widget-container dd,
#ta-widget-container h1,
#ta-widget-container h2,
#ta-widget-container h3,
#ta-widget-container h4,
#ta-widget-container h5,
#ta-widget-container h6,
#ta-widget-container hr,
#ta-widget-container figure,
#ta-widget-container p,
#ta-widget-container pre {
  margin: 0;
}
#ta-widget-container fieldset {
  margin: 0;
  padding: 0;
}
#ta-widget-container legend {
  padding: 0;
}
#ta-widget-container ol,
#ta-widget-container ul,
#ta-widget-container menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
/*
      Prevent resizing textareas horizontally by default.
      */
#ta-widget-container textarea {
  resize: vertical;
}
/*
      1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
      2. Set the default placeholder color to the user's configured gray 400 color.
      */
#ta-widget-container input::placeholder,
#ta-widget-container textarea::placeholder {
  opacity: 1; /* 1 */
  color: #8f8f8f; /* 2 */
}
/*
      Set the default cursor for buttons.
      */
#ta-widget-container button,
#ta-widget-container [role="button"] {
  cursor: pointer;
}
/*
      Make sure disabled buttons don't get the pointer cursor.
      */
#ta-widget-container :disabled {
  cursor: default;
}
/*
      1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
      2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
         This can trigger a poorly considered lint error in some tools but is included by design.
      */
#ta-widget-container img,
#ta-widget-container svg,
#ta-widget-container video,
#ta-widget-container canvas,
#ta-widget-container audio,
#ta-widget-container iframe,
#ta-widget-container embed,
#ta-widget-container object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}
/*
      Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
      */
#ta-widget-container img,
#ta-widget-container video {
  max-width: 100%;
  height: auto;
}
